import * as React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import SEO from '../components/seo'

const Privacy = ({data}) => {
    return(
        <Layout>
          <SEO title="Privacy e Cookie Policy" />
            <section className="px-6 py-12">
                <div className="container mx-auto">
                    <div className="prose max-w-none prose-persian-green">
                        {parse(data.iubendaDocument.privacyPolicy)}
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Privacy

export const query = graphql`
  {
    iubendaDocument {
      privacyPolicy
    }
  }
`